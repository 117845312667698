import { styled } from '@mui/material/styles';
import { gridDimensionsSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';

const StickyBorder = styled('div')<{ width: number; height: number }>(
  ({ width, height, theme }) => ({
    position: 'sticky',
    left: 0,
    width: `${width}px`,
    height: `${height}px`,
    border: `1px solid ${theme.palette.tableBorder.main}`,
    borderTop: 'none',
    pointerEvents: 'none',
    zIndex: 99999,
  }),
);

export type StickyBorderBoxProps = { height?: number };

export const StickyBorderBox = ({ height = 52 }: StickyBorderBoxProps) => {
  const apiRef = useGridApiContext();

  const { root: visibleViewport } = useGridSelector(apiRef, gridDimensionsSelector);

  return <StickyBorder width={visibleViewport.width} height={height} />;
};
