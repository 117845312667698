import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';

type SkeletonRowProps = {
  showStartSkeleton?: boolean;
  showEndSkeleton?: boolean;
  className?: string;
};

const SkeletonRowRoot = styled('div')({
  display: 'flex',
  width: '100%',
  gap: '16px',
  height: '52px',
  alignItems: 'center',
});

const SmallSkeleton = styled(Skeleton)({
  flex: '0 0 24px',
  height: '16px',
  borderRadius: '8px',
  transform: 'none',
});

const MainSkeleton = styled(Skeleton)({
  display: 'block',
  flex: '1',
  height: '16px',
  borderRadius: '8px',
  transform: 'none',
});

const SkeletonRow = ({ showStartSkeleton, showEndSkeleton, className }: SkeletonRowProps) => {
  return (
    <SkeletonRowRoot className={className} data-testid="skeleton-row">
      {showStartSkeleton && <SmallSkeleton />}
      <MainSkeleton />
      {showEndSkeleton && <SmallSkeleton />}
    </SkeletonRowRoot>
  );
};

type SkeletonLoaderProps = {
  numberOfRows?: number;
  showStartSkeleton?: boolean;
  showEndSkeleton?: boolean;
  pagination?: boolean;
};

export const SkeletonLoader = ({
  numberOfRows,

  showStartSkeleton,
  showEndSkeleton,
}: SkeletonLoaderProps) => {
  return (
    <Box data-testid="Table skeleton">
      <SkeletonRow
        showStartSkeleton={showStartSkeleton}
        showEndSkeleton={showEndSkeleton}
        className="MuiDataGrid-skeletonHeaderRow"
      />
      {Array(numberOfRows)
        .fill(0)
        .map((_, i) => (
          <React.Fragment key={i}>
            <SkeletonRow
              showStartSkeleton={showStartSkeleton}
              showEndSkeleton={showEndSkeleton}
              className="MuiDataGrid-skeletonBodyRow"
            />
          </React.Fragment>
        ))}
    </Box>
  );
};
