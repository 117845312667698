import { createContext, useCallback, useContext } from 'react';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  fetchRSimEnability,
  RSimEnabilities,
  setRSimEnability,
} from 'rSimInventory/RSimApi/rSimApi';
import { AlertTitle } from 'common/Snackbar/AlertTitle';
import { AutoHideSnackbar } from 'common/Snackbar/AutoHideSnackbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';

type KickRsimProviderProps = {
  children: React.ReactNode;
  euiccs: string[];
  queryKey?: QueryKey;
};

type KickSimMutationArgs = {
  enabled: boolean;
  euicc: string;
  profileId: 0 | 1;
};

type KickSimContextType = {
  isPrimaryProfileDisabled: (euicc: string) => boolean;
  isSecondaryProfileDisabled: (euicc: string) => boolean;
  kickSim: (args: KickSimMutationArgs) => void;
};

const KickSimContext = createContext<KickSimContextType>(null!);

export const useKickSimContext = () => {
  const kickSimContext = useContext(KickSimContext);

  if (!kickSimContext) {
    throw new Error('useKickSimContext has to be used within <KickSimContext.Provider>');
  }

  return kickSimContext;
};

export const KickRsimProvider: React.FC<KickRsimProviderProps> = ({
  children,
  euiccs,
  queryKey,
}) => {
  const { t } = useTranslation();
  const ability = useAbility();
  const { data: rsimEnability } = useQuery({
    queryKey: queryKey ? [queryKey] : ['RSimInventory', 'Enability', euiccs],
    queryFn: ({ signal }) => fetchRSimEnability(euiccs, signal),
    keepPreviousData: false,
    cacheTime: 0,
    suspense: false,
    enabled: ability.can(Actions.simulate, Subjects.outage),
  });

  const isPrimaryProfileDisabled = useCallback(
    (euicc: keyof RSimEnabilities) => {
      if (!rsimEnability) {
        return false;
      }

      return rsimEnability?.[euicc]?.isPrimaryProfileDisabled;
    },
    [rsimEnability],
  );

  const isSecondaryProfileDisabled = useCallback(
    (euicc: keyof RSimEnabilities) => {
      if (!rsimEnability) {
        return false;
      }

      return rsimEnability?.[euicc]?.isSecondaryProfileDisabled;
    },
    [rsimEnability],
  );

  const queryClient = useQueryClient();
  const {
    error,
    reset,
    mutate: kickSim,
  } = useMutation({
    mutationFn: ({ enabled, euicc, profileId }: KickSimMutationArgs) =>
      setRSimEnability(euicc, profileId, enabled),
    onMutate: async ({ enabled, euicc, profileId }) => {
      const key = queryKey ? [queryKey] : ['RSimInventory', 'Enability', euiccs];
      await queryClient.cancelQueries({ queryKey });

      const oldData = queryClient.getQueryData<RSimEnabilities>(key);

      queryClient.setQueryData<RSimEnabilities>(key, (data) => {
        if (!data) {
          throw new Error('No data to update');
        }

        return {
          ...data,
          [euicc]: {
            isPrimaryProfileDisabled:
              profileId === 0 ? !enabled : data[euicc].isPrimaryProfileDisabled,
            isSecondaryProfileDisabled:
              profileId === 1 ? !enabled : data[euicc].isSecondaryProfileDisabled,
          },
        };
      });

      return { queryKey: key, oldData };
    },
    onError: (_err, _variables, context) => {
      if (context?.oldData) {
        queryClient.setQueryData(context.queryKey, context.oldData);
      }
    },
  });

  return (
    <KickSimContext.Provider
      value={{
        isPrimaryProfileDisabled,
        isSecondaryProfileDisabled,
        kickSim,
      }}
    >
      {children}
      <AutoHideSnackbar severity="error" open={!!error} onClose={() => reset()}>
        {''} {/* Without this component throw a error and crash app */}
        <AlertTitle>{t('common.requestFailed')}!</AlertTitle>
        <Typography variant="text1White">{t('common.somethingWrong')}</Typography>
      </AutoHideSnackbar>
    </KickSimContext.Provider>
  );
};
