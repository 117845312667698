import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { NoRowsOverlay } from './Components/NoRowsOverlay';
import { CellConfiguration, CellLoader } from './Components/Cells/CellLoader';
import React, { useMemo, useState } from 'react';
import {
  DataGridProProps,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ActionCell } from './Components/Cells/ActionCell';
import { HeaderCell } from './Components/Header/HeaderCell';
import { Pagination } from './Components/Pagination/Pagination';
import { SkeletonLoader } from './Components/Loaders/SkeletonLoader';
import { ColumnHeaders } from './Components/Rows/ColumnHeaders';
import { GridRow } from './Components/Rows/GridRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material';
import { useAbility } from 'permissions/hooks/useAbility';
import { CollapseAllButton } from './Components/Buttons/CollapseAll';

export type Configuration = Pick<
  GridColDef,
  | 'field'
  | 'resizable'
  | 'sortable'
  | 'headerName'
  | 'pinnable'
  | 'flex'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
> & {
  subField?: { field: string; headerName: string; valueGetter?: (value: any) => string };
  valueGetter?: (value: any) => string;
  permision?: {
    action: string;
    subject: string;
  };
};

type ConfigurableTableProps = {
  disablePagination?: boolean;
  disableColumnReorder?: boolean;
  configuration: Configuration[];
  cellConfiguration: CellConfiguration;
  cellProps?: any;
  //TODO: Imporve typings (generic)
  rows?: Record<string, any>[];
  totalNumberOfItems?: number;
  actions?: any;
  'data-testid'?: string;
  page?: number;
  pageSize?: number;
  onPaginationChange?: (pagination: GridPaginationModel) => void;
  slots?: DataGridProProps['slots'];
  getDetailPanelContent?: DataGridProProps['getDetailPanelContent'];
  getDetailPanelHeight?: DataGridProProps['getDetailPanelHeight'];

  loading?: DataGridProProps['loading'];
  getRowId?: DataGridProProps['getRowId'];
};

//To remove when use this component in SimInventory
const CustomizeMuiDataGrid = styled(DataGridPro)<{
  displayDetailsPanel?: boolean;
  bottomContainerHeight?: number;
}>(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderTop: 'none',
  },
  '& .MuiDataGrid-filler--pinnedRight': {
    borderTop: 'none !important',
  },
  '& .MuiDataGrid-scrollbarFiller--borderTop': {
    borderTop: 'none !important',
  },
  '& .MuiDataGrid-detailPanel': {
    borderBottom: `1px solid ${theme.palette.tableBorder.main}`,
  },
}));

export const configurableCellRender =
  (cellConfiguration: any, cellProps?: any) => (params: GridRenderCellParams) => {
    return <CellLoader {...params} cellConfiguration={cellConfiguration} cellProps={cellProps} />;
  };

export const ConfigurableTableContent = ({
  disablePagination,
  configuration,
  cellConfiguration,
  cellProps,
  rows = [],
  totalNumberOfItems,
  actions,
  page = 0,
  pageSize = 25,
  onPaginationChange,
  slots,
  ...props
}: ConfigurableTableProps) => {
  const ability = useAbility();
  const dialogsToRender = useMemo(() => {
    const availableActions = actions.filter((action: any) => {
      if (action.type !== 'dialog') {
        return false;
      }

      if (action.permision && ability.cannot(action.permision.action, action.permision.subject)) {
        return false;
      }

      return true;
    });

    return availableActions;
  }, []);

  const [dialogs, setDialogs] = useState(() => {
    return dialogsToRender.reduce((dialogs: any, currentValue: any) => {
      return {
        ...dialogs,
        [currentValue.id]: null,
      };
    }, {});
  });

  const selectDialog = (dialogId: string, row: any) => {
    setDialogs((currentDialogs: any) => ({
      ...currentDialogs,
      [dialogId]: row,
    }));
  };

  const unselectDialog = (dialogId: string) => {
    setDialogs((currentDialogs: any) => ({
      ...currentDialogs,
      [dialogId]: null,
    }));
  };

  const columnsConfiguration = useMemo(() => {
    const definedcolumns = configuration
      .filter((column) => {
        if (column.permision) {
          return ability.can(column.permision.action, column.permision.subject);
        }

        return true;
      })
      .map((column) => {
        const columnDef: GridColDef = {
          ...column,
          renderCell: configurableCellRender(cellConfiguration, cellProps),
          renderHeader: (props) => {
            return (
              <HeaderCell
                primaryText={props.colDef.headerName || ''}
                //@ts-ignore
                secondaryText={props.colDef.subField?.headerName}
              />
            );
          },
        };

        return columnDef;
      });

    let columns = [];

    if (props.getDetailPanelContent) {
      columns.push({
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        width: 54,
        renderHeader: () => {
          return <CollapseAllButton />;
        },
      });
    }

    columns = [...columns, ...definedcolumns];

    const filler: GridColDef = {
      field: 'filler',
      headerName: '',
      display: 'flex',
    };
    columns.push(filler);
    if (actions) {
      //Add callback for type component
      const mappedAction = actions.map((action: any) => {
        if (action.type === 'dialog') {
          return {
            ...action,
            actionCallback: (data: any) => selectDialog(action.id, data),
          };
        }

        return action;
      });

      const actionsColDef: GridColDef = {
        field: 'actions',
        headerName: '',
        resizable: false,
        width: 60,
        minWidth: 60,
        maxWidth: 60,

        renderCell: (props) => {
          return <ActionCell actions={mappedAction} data={props.row} />;
        },

        sortable: false,
        pinnable: false,
      };
      columns.push(actionsColDef);
    }

    return columns;
  }, [configuration, actions, props.getDetailPanelContent !== undefined]);

  const paginationModel = useMemo(() => {
    return {
      page: page,
      pageSize: pageSize,
    };
  }, [page, pageSize]);

  const apiRef = useGridApiRef();

  return (
    <>
      <CustomizeMuiDataGrid
        {...props}
        displayDetailsPanel={!!props.getDetailPanelContent}
        apiRef={apiRef}
        hideFooter={disablePagination}
        pagination={!disablePagination}
        disableColumnMenu
        columns={columnsConfiguration}
        columnBufferPx={999999}
        rowBufferPx={420}
        rowCount={totalNumberOfItems}
        onPaginationModelChange={onPaginationChange}
        paginationModel={paginationModel}
        columnHeaderHeight={props.loading ? 0 : 42}
        rows={rows}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          pagination: Pagination,
          loadingOverlay: () => <SkeletonLoader numberOfRows={2} />,
          columnHeaders: ColumnHeaders,
          row: GridRow,
          detailPanelExpandIcon: KeyboardArrowDownIcon,
          detailPanelCollapseIcon: KeyboardArrowUpIcon,
          ...slots,
        }}
        paginationMode="server"
        pinnedColumns={{
          right: ['actions'],
        }}
      />
      {dialogsToRender.map((action: any) => {
        return (
          <React.Fragment key={action.id}>
            <action.Component
              open={!!dialogs[action.id]}
              data={dialogs[action.id]}
              onClose={() => {
                unselectDialog(action.id);
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export const ConfigurableTable = (props: ConfigurableTableProps) => {
  return (
    <>
      <ConfigurableTableContent {...props} />
    </>
  );
};
