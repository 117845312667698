import { createContext, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { RSimInventoryData, RSimInventoryItem } from 'rSimInventory/RSimApi/rSimApi.interface';

type RSimUpdateContextProps = {
  updateTagsForRSim: (euicc: string, tags: any) => void;
};

export const rSimUpdateContext = createContext<RSimUpdateContextProps>(
  {} as RSimUpdateContextProps,
);

export const useRSimUpdateContext = () => useContext(rSimUpdateContext);

export const RSimUpdateProvider = ({ queryKey, children }: any) => {
  const queryClient = useQueryClient();
  const updateTagsForRSim = async (euicc: string, tags: any) => {
    const simProfiles: RSimInventoryData | undefined = queryClient.getQueryData(queryKey);

    if (!simProfiles?.items.length) {
      return;
    }

    //@ts-ignore
    queryClient.setQueryData(queryKey, (oldData: RSimInventoryData) => {
      if (!oldData) {
        return oldData;
      }

      const newData = {
        ...oldData,
        items: oldData?.items.map((simProfile: RSimInventoryItem) => {
          if (simProfile.euicc === euicc) {
            return { ...simProfile, tags: { items: tags, hasMore: false } };
          }

          return simProfile;
        }),
      };

      return newData;
    });
  };

  return (
    <rSimUpdateContext.Provider value={{ updateTagsForRSim }}>
      {children}
    </rSimUpdateContext.Provider>
  );
};
