import Typography from '@mui/material/Typography';

export const TextCell = ({ value, subValue }: any) => {
  return (
    <div>
      <Typography variant="text1Primary" component="div" data-testid="primaryText">
        {value}
      </Typography>
      <Typography variant="text5Secondary" component="div" data-testid="secondaryText">
        {subValue}
      </Typography>
    </div>
  );
};
