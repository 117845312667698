import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button } from 'common/components/Button';
import { Dialog } from 'common/components/Dialog/Dialog';
import { useMutation, useQuery } from 'react-query';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConfigurationIcon } from 'assets/images/configuration.svg';
import { ReactComponent as PendingIcon } from 'assets/images/pending.svg';

import MenuItem from '@mui/material/MenuItem';

import Grid from '@mui/material/Grid';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { useAbility } from 'permissions/hooks/useAbility';
import { PossibleConfigurationToRevert } from 'rSimInventory/RSimApi/rSimConfigurationApi.types';
import {
  fetchRSimCurrentAppliedConfigurationDetails,
  fetchRSimPossibleOTAConfigurationsToRevert,
  revertConfigurationVersion,
} from 'rSimInventory/RSimApi/rSimConfigurationApi';
import { Select } from 'common/components/Inputs/Select/Select';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { AutoHideSnackbar } from 'common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from 'common/Snackbar/AlertTitle';

type RSimInventoryConfigurationDialogProps = {
  open: boolean;
  data: any;
  onClose: () => void;
};

type ConfigurationDetailsRowProps = {
  title: string;
  value: string;
};

const ConfigurationDetailsRow: React.FC<ConfigurationDetailsRowProps> = ({ title, value }) => (
  <>
    <Grid xs={7} sx={{ mt: 2 }}>
      <Typography variant="text2Secondary" sx={{ ml: 4 }}>
        {title}
      </Typography>
    </Grid>
    <Grid xs={5} sx={{ mt: 2 }}>
      <Typography
        sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', ml: 7 }}
        variant="tableCellPrimary"
      >
        {value || '-'}
      </Typography>
    </Grid>
  </>
);

export const RSimInventoryConfigurationDialogContent = ({
  open,
  data: configuration,
  onClose,
}: RSimInventoryConfigurationDialogProps) => {
  const [selectedConfiguration, setSelectedConfiguration] =
    useState<PossibleConfigurationToRevert | null>(null);

  const { t } = useTranslation();

  const ability = useAbility();

  const { data: possibleConfigurationsToRevert } = useQuery(
    ['possibleConfigurationsToRevert', configuration.euicc],
    async () => fetchRSimPossibleOTAConfigurationsToRevert(configuration.euicc),
    {
      enabled: ability.can(Actions.restore, Subjects.otaConfiguration),
      initialData: ability.can(Actions.restore, Subjects.otaConfiguration) ? undefined : [],
    },
  );

  const { data: appliedConfigurationDetails } = useQuery(
    ['appliedConfigurationDetails', configuration.euicc],
    async () => fetchRSimCurrentAppliedConfigurationDetails(configuration.euicc),
    {
      enabled: ability.can(Actions.read, Subjects.otaConfiguration),
    },
  );

  const [restoreStatus, setRestoreStatus] = useState<{ success: boolean; error: boolean }>({
    success: false,
    error: false,
  });
  const { mutate: restoreConfiguration } = useMutation(
    async () => revertConfigurationVersion(selectedConfiguration!),
    {
      onSuccess: async (res) => {
        setSelectedConfiguration(null);
        onClose();

        setRestoreStatus({ success: res.status === 200, error: res.status !== 200 });
      },
    },
  );

  return (
    <>
      <Dialog
        open={open}
        title={
          !selectedConfiguration
            ? t('rSimInventory.configurationDetails')
            : t('rSimInventory.restoreConfiguration')
        }
        subtitle={`rSIM ${configuration.euicc}`}
        onClose={onClose}
        size="small"
        actions={
          <>
            {!selectedConfiguration && (
              <>
                <SimInventoryCan I={Actions.restore} a={Subjects.otaConfiguration}>
                  {possibleConfigurationsToRevert && possibleConfigurationsToRevert.length > 0 && (
                    <Select
                      name={t('rSimInventory.restoreConfiguration')}
                      //@ts-ignore
                      displayEmpty
                      //@ts-ignore
                      renderValue={
                        //@ts-ignore
                        selectedConfiguration && selectedConfiguration.rsimOtaProfileVersion !== ''
                          ? undefined
                          : () => t('rSimInventory.restoreConfiguration')
                      }
                      fullWidth={false}
                      sx={{ marginRight: '1rem', width: '190px' }}
                      onChange={(event) => {
                        if (possibleConfigurationsToRevert) {
                          // @ts-ignore
                          const conf = possibleConfigurationsToRevert[+event.target.value];
                          if (conf) {
                            setSelectedConfiguration(conf);
                          } else {
                            setSelectedConfiguration(null);
                          }
                        }
                      }}
                    >
                      {possibleConfigurationsToRevert.map((d, i) => (
                        <MenuItem key={i} value={i}>
                          {d.rsimOtaProfileIdentifier} {d.rsimOtaProfileVersion}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </SimInventoryCan>
                <Button onClick={onClose}>{t('common.close')}</Button>
              </>
            )}

            {selectedConfiguration && (
              <>
                <Button onClick={() => setSelectedConfiguration(null)} color="secondary">
                  {t('common.cancel')}
                </Button>

                <Button onClick={() => restoreConfiguration()}>{t('common.confirm')}</Button>
              </>
            )}
          </>
        }
      >
        <Stack direction="row" gap={55} alignItems="center">
          {!selectedConfiguration && (
            <>
              <Typography variant="text2Secondary" sx={{ ml: 4.25 }}>
                {t('rSimInventory.configuration')}
              </Typography>

              <Box sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '34px',
                  }}
                >
                  {configuration.isCustomConfigurationDetected && (
                    <Tooltip title={t('rSimInventory.customConfigurationDetected')}>
                      <ConfigurationIcon
                        width="16px"
                        height="16px"
                        role="img"
                        data-testid="custom-configuration-icon"
                      />
                    </Tooltip>
                  )}
                  {configuration.isPendingConfiguration && (
                    <Tooltip title={t('rSimInventory.configurationChangePending')}>
                      <PendingIcon
                        width="18px"
                        height="18px"
                        role="img"
                        data-testid="change-pending-icon"
                      />
                    </Tooltip>
                  )}
                </Box>
                {configuration.appliedOtaConfigurationProfile && (
                  <Typography sx={{ ml: 1 }} variant="tableCellPrimary">
                    {configuration.appliedOtaConfigurationProfile || ''}
                  </Typography>
                )}

                {configuration.appliedOtaConfigurationVersion && (
                  <Typography marginLeft="8px" variant="tableCellSecondary">
                    {configuration.appliedOtaConfigurationVersion || ''}
                  </Typography>
                )}
                {!configuration.appliedOtaConfigurationProfile && (
                  <Typography variant="tableCellPrimary">
                    {t('rSimInventory.noConfigurationApplied')}
                  </Typography>
                )}
              </Box>
            </>
          )}

          {selectedConfiguration && (
            <Typography variant="text1Primary">
              Are you sure you want to restore configuration{' '}
              <b>
                {' '}
                {selectedConfiguration?.rsimOtaProfileIdentifier}{' '}
                {selectedConfiguration?.rsimOtaProfileVersion}
              </b>
              ?
            </Typography>
          )}
        </Stack>

        {appliedConfigurationDetails && !selectedConfiguration && (
          <>
            <Grid container spacing={0} sx={{ mt: 6 }}>
              <Grid>
                <Typography variant="h4Primary" sx={{ ml: 4.25 }}>
                  {t('rSimInventory.currentlyAppliedConfiguration')}{' '}
                  {configuration.isCustomConfigurationDetected && <>(custom)</>}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              sx={{ borderLeft: (theme) => `1px solid ${theme.palette.text.tertiary}` }}
            >
              <ConfigurationDetailsRow
                title={t('rSimInventory.connectionDelayEventCounter')}
                value={appliedConfigurationDetails.connectionDelayEventCounter}
              />
              <ConfigurationDetailsRow
                title={t('rSimInventory.requestedPoolInterval')}
                value={appliedConfigurationDetails.requestedPoolInterval}
              />
              <ConfigurationDetailsRow
                title={t('rSimInventory.commsCheckEventCounter')}
                value={appliedConfigurationDetails.commsCheckEventCounter}
              />
              <ConfigurationDetailsRow
                title={t('rSimInventory.maxConsecutiveFailedPoll')}
                value={appliedConfigurationDetails.maxConsecutiveFailedPoll}
              />
              <ConfigurationDetailsRow
                title={t('rSimInventory.backupCancellationDelayEventCounter')}
                value={appliedConfigurationDetails.backupCancellationDelayEventCounter}
              />
              <ConfigurationDetailsRow
                title={t('rSimInventory.maxGlobalSwapCounter')}
                value={appliedConfigurationDetails.maxGlobalSwapCounter}
              />
              <ConfigurationDetailsRow
                title={t('rSimInventory.resilienceRestorationDelayEventCounter')}
                value={appliedConfigurationDetails.resilienceRestorationDelayEventCounter}
              />
              <ConfigurationDetailsRow
                title={t('rSimInventory.maxRandConnectionDelayEventCounter')}
                value={appliedConfigurationDetails.maxRandConnectionDelayEventCounter}
              />
              <ConfigurationDetailsRow
                title={t('rSimInventory.maxRandBackupCancellationDelayEventCounter')}
                value={appliedConfigurationDetails.maxRandBackupCancellationDelayEventCounter}
              />
            </Grid>
          </>
        )}
      </Dialog>
      <Stack>
        <AutoHideSnackbar
          severity="success"
          open={restoreStatus.success}
          onClose={() => setRestoreStatus({ ...restoreStatus, success: false })}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle> {t('rSimInventory.restoreConfigurationSend')}</AlertTitle>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          severity="error"
          open={restoreStatus.error}
          onClose={() => setRestoreStatus({ ...restoreStatus, error: false })}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{t('rSimInventory.restoreConfigurationFailed')}</AlertTitle>
        </AutoHideSnackbar>
      </Stack>
    </>
  );
};

export const RSimConfigurationDialog = (props: any) => {
  if (!props.open) {
    return null;
  }

  return <RSimInventoryConfigurationDialogContent {...props} />;
};
