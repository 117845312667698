import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';

import { KickRsimProvider } from './KickRsimProvider';
import {
  LastActiveProfileFilter,
  LastUpdateTimeFilters,
  SimulateOutageFilter,
} from 'rSimInventory/Filters/data/filters.constants';
import { Routes } from 'Routes.constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSorting } from '../../common/hooks/useSorting';
import { useRSimInventory } from 'rSimInventory/hooks/useRSimInventory';
import { RSimProfileId } from 'rSimInventory/models/RSimProfile.model';
import { getHuminizeDurationInSecFunction } from 'i18n/dateLocale';
import { Actions, Subjects } from 'permissions/ability';
import {
  ConfigurableTable,
  Configuration,
} from 'common/components/ConfigurableTable/ConfigurableTable';
import { TextCell } from 'common/components/ConfigurableTable/Components/Cells/TextCell';
import { RSimSimTable } from './SimInventory/RSimSimTable';
import { DetailsPanel } from 'common/components/ConfigurableTable/Components/Containers/DetailsPanel';
import { LinkTextCell } from 'common/components/ConfigurableTable/Components/Cells/LinkTextCell';
import { RSimStatusCell } from './Cells/RSimStatusCell';
import { RSimEditDialog } from './Dialogs/RSimEditDialog';
import { RSimUpdateProvider } from './Providers/RSimUpdateProvider';
import { TagsCell } from './Cells/TagsCell';
import { RSimConfigurationDialog } from './Dialogs/RSimConfigurationDialog';
import { DurationCell } from 'common/components/ConfigurableTable/Components/Cells/DurationCell';

const CustumizedConfigurationTable = styled(ConfigurableTable)({
  '& .MuiDataGrid-columnHeader[data-field="rSimStatus"] .MuiTypography-tableHeaderPrimary': {
    textTransform: 'none',
  },
});
interface RSimTableProps {
  searchText: string;
  chosenIdType: RSimProfileId;
  lastActiveProfile: LastActiveProfileFilter;
  lastUpdateTime: LastUpdateTimeFilters;
  tags: string;
  connectionId: string;
  orderNumber: string;
  simulateOutage: SimulateOutageFilter;
  accounts: string;
  eidFrom: string;
  eidTo: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const humanize = getHuminizeDurationInSecFunction();

const RSimEIDLinkCell = ({ value }: any) => {
  return <LinkTextCell value={value} to={`${Routes.rSimInventory}/${value}`} />;
};

export const RSimTable: React.FC<RSimTableProps> = ({
  searchText,
  chosenIdType,
  lastActiveProfile,
  lastUpdateTime,
  tags,
  connectionId,
  orderNumber,
  simulateOutage,
  accounts,
  eidFrom,
  eidTo,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sorting, setSortParams } = useSorting('rsim-order');

  const searchIds = useMemo(() => {
    return searchText.match(/[0-9]+/g) || [''];
  }, [searchText]);

  const {
    queryResult,
    pagination: { page, setPage, rowsPerPage, setRowsPerPage, totalNumberOfItems },
    queryKey,
  } = useRSimInventory({
    searchIds: searchIds,
    searchText,
    chosenIdType,
    lastActiveProfile,
    lastUpdateTime,
    tags,
    connectionId,
    orderNumber,
    simulateOutage,
    accounts,
    eidFrom,
    eidTo,
    sorting,
  });

  const euiccs =
    useMemo(() => queryResult.data?.items.map((item) => item.euicc), [queryResult.data]) || [];

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const configuration: Configuration[] = [
    {
      field: 'euicc',
      headerName: t('common.eid'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
    },
    {
      field: 'rSimStatus',
      headerName: t('rSimInventory.rsimStatus'),
      resizable: false,
      sortable: false,
      pinnable: false,
    },
    {
      field: 'lastPollingTimestamp',
      headerName: t('rSimInventory.lastPollingTimestamp'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
    },
    {
      field: 'accountName',
      headerName: t('common.accountName'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
      subField: {
        field: 'accountNumber',
        headerName: t('common.accountNumber'),
      },
    },
    {
      field: 'dataUsage',
      headerName: t('common.usage'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
    },
    {
      field: 'inMinuteUsage',
      headerName: t('common.inMinuteUsage'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
      subField: {
        field: 'outMinuteUsage',
        headerName: t('common.outMinuteUsage'),
      },
    },
    {
      field: 'inTextUsage',
      headerName: t('common.inTextUsage'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
      subField: {
        field: 'outTextUsage',
        headerName: t('common.outTextUsage'),
      },
    },
    {
      field: 'configuration',
      headerName: t('rSimInventory.configuration'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
      subField: {
        field: 'version',
        headerName: t('rSimInventory.version'),
      },
    },
    {
      field: 'tags',
      headerName: t('common.tag'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
    },
  ];

  return (
    <KickRsimProvider euiccs={euiccs}>
      <RSimUpdateProvider queryKey={queryKey}>
        <CustumizedConfigurationTable
          disableColumnReorder
          actions={[
            {
              label: 'View Details',
              type: 'custom',
              actionCallback: (data: any) => {
                navigate(`${Routes.rSimInventory}/${data.euicc}`, {
                  state: {
                    previousPath: `${pathname}${search}`,
                  },
                });
              },
            },
            {
              id: 'edit',
              label: 'Edit',
              type: 'dialog',
              Component: RSimEditDialog,
              permission: {
                action: Actions.edit,
                subject: Subjects.tags,
              },
            },
            {
              id: 'otaConfiguration',
              label: t('rSimInventory.configurationDetails'),
              type: 'dialog',
              Component: RSimConfigurationDialog,
              permission: {
                action: Actions.edit,
                subject: Subjects.tags,
              },
            },
          ]}
          data-testid="rsims inventory table"
          configuration={configuration}
          getDetailPanelContent={(props) => {
            return (
              <DetailsPanel>
                <RSimSimTable
                  euicc={props.row.euicc}
                  primaryIccid={props.row.primaryIccid}
                  secondaryIccid={props.row.secondaryIccid}
                  status={props.row.status}
                />
              </DetailsPanel>
            );
          }}
          getDetailPanelHeight={() => 176}
          rows={queryResult.data?.items || []}
          cellConfiguration={{
            default: TextCell,
            euicc: RSimEIDLinkCell,
            rSimStatus: RSimStatusCell,
            tags: TagsCell,

            inMinuteUsage: DurationCell,
          }}
          totalNumberOfItems={totalNumberOfItems}
          onPaginationChange={(pagination) => {
            if (pagination.page !== page) {
              setPage(pagination.page);
            }

            if (pagination.pageSize !== rowsPerPage) {
              setRowsPerPage(pagination.pageSize);
            }
          }}
          page={page}
          pageSize={rowsPerPage}
        />
      </RSimUpdateProvider>
      {/* <TableLayoutWithPagination
        noItemsMessage={<NoDataMessage searchText={searchText} prevSearchText={prevSearchText} />}
        page={page}
        onPageChange={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(page) => setRowsPerPage(page)}
        totalNumberOfItems={totalNumberOfItems}
        paginationSx={{
          ...verticalBorders,
          ...topDarkBorder,
        }}
        table={(scrollbarsRef) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flex: 0 }}>
              <RSimTableBase
                collapseAllButtonVisible={Object.values(openedRows).some((opened) => opened)}
                onCloseAll={() => {
                  setOpenedRows(getOpenedRowsMap());
                }}
                sortedColumn={sorting}
                onSortChange={(columnName, sort) => {
                  if (columnName && sort) {
                    localStorage.setItem('rsim-order', `${columnName}:${sort}`);
                    setSortParams(`${columnName}:${sort}`);
                  } else {
                    localStorage.removeItem('rsim-order');
                  }
                }}
              >
                {queryResult.data?.items.map(
                  (
                    {
                      id,
                      euicc,
                      status,
                      accountName,
                      accountNumber,
                      primaryIccid,
                      dataUsage,
                      inMinuteUsage,
                      inTextUsage,
                      outMinuteUsage,
                      outTextUsage,
                      lastPollingTimestamp,
                      tags,
                      appliedOtaConfigurationProfile,
                      appliedOtaConfigurationVersion,
                      isPendingConfiguration,
                      isCustomConfigurationDetected,
                    },
                    index,
                  ) => {
                    return (
                      <React.Fragment key={id}>
                        <CollapseRow
                          onCollapseEnd={() => {
                            setTimeout(() => {
                              scrollbarsRef.current && scrollbarsRef.current.forceUpdate();
                            }, 100);
                          }}
                          open={!!openedRows[id]}
                          onCollapseButtonClick={(open) => {
                            setOpenedRows((prevOpendRows) => ({
                              ...prevOpendRows,
                              [id]: open,
                            }));
                          }}
                          collapseChildren={
                            <div data-testid={`sim-inventory-${euicc}`}>
                              <Suspense
                                fallback={
                                  <TableSkeleton
                                    numberOfRows={2}
                                    showEndSkeleton
                                    pagination={false}
                                  />
                                }
                              >
                                <ThemeProvider theme={rsimTheme}>
                                  <div data-testid={`sim-inventory-table-${euicc}`}>
                                    <RSimSimInventoryTable
                                      euicc={euicc}
                                      primaryIccid={primaryIccid}
                                      status={status}
                                      onDataLoaded={() =>
                                        scrollbarsRef.current && scrollbarsRef.current.forceUpdate()
                                      }
                                    />
                                  </div>
                                </ThemeProvider>
                              </Suspense>
                            </div>
                          }
                          renderMenu={({ onClose }) => (
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  navigate(`${Routes.rSimInventory}/${euicc}`, {
                                    state: {
                                      previousPath: `${pathname}${search}`,
                                    },
                                  });
                                  onClose();
                                }}
                              >
                                <ListItemText>{t('bulkProvisioning.viewDetails')}</ListItemText>
                              </MenuItem>
                              <SimInventoryCan I={Actions.edit} a={Subjects.tags}>
                                <MenuItem
                                  onClick={() => {
                                    setEditedRsim(euicc);
                                    onClose();
                                  }}
                                >
                                  <ListItemText>Edit</ListItemText>
                                </MenuItem>
                              </SimInventoryCan>
                              <SimInventoryCan I={Actions.read} a={Subjects.otaConfiguration}>
                                {(isCustomConfigurationDetected ||
                                  appliedOtaConfigurationProfile ||
                                  status !== RsimProfileStatus.Unknown) && (
                                  <MenuItem
                                    onClick={() => {
                                      setEditedConfiguration({
                                        euicc,
                                        appliedOtaConfigurationProfile,
                                        appliedOtaConfigurationVersion,
                                        isCustomConfigurationDetected,
                                        isPendingConfiguration,
                                      });
                                    }}
                                  >
                                    <ListItemText>
                                      {t('rSimInventory.configurationDetails')}
                                    </ListItemText>
                                  </MenuItem>
                                )}
                              </SimInventoryCan>
                            </MenuList>
                          )}
                          isPreviousOpen={isPreviousOpen(index)}
                          isFirst={index === 0}
                        >
                          <TwoValueCellLayout
                            sx={{
                              pl: '12px',
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                            primaryContent={
                              <CopyableLink value={euicc} to={`${Routes.rSimInventory}/${euicc}`} />
                            }
                          />
                          <TableCell
                            align="center"
                            sx={{ py: 1.5, ...topBorder, borderBottom: 'none' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <RSimStatusIndicator euicc={euicc} status={status} />
                            </Box>
                          </TableCell>
                          <PrioritizedTwoValuesCell
                            primaryText={lastPollingTimestamp}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <PrioritizedTwoValuesCell
                            primaryText={accountName}
                            secondaryText={accountNumber}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <PrioritizedTwoValuesCell
                            primaryText={dataUsage.toString()}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <PrioritizedTwoValuesCell
                            primaryText={humanize(inMinuteUsage)}
                            secondaryText={humanize(outMinuteUsage)}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <PrioritizedTwoValuesCell
                            primaryText={inTextUsage.toString()}
                            secondaryText={outTextUsage.toString()}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <TableCell
                            sx={{
                              verticalAlign: 'center',
                              py: 2,
                              height: '52px',
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          >
                            <Box
                              sx={{ whiteSpace: 'nowrap', display: 'flex' }}
                              data-testid="primaryText"
                            >
                              <ContentCopyExtension value={appliedOtaConfigurationProfile || ''}>
                                <Typography
                                  marginRight={
                                    isCustomConfigurationDetected || isPendingConfiguration
                                      ? '5px'
                                      : ''
                                  }
                                  variant="tableCellPrimary"
                                >
                                  {appliedOtaConfigurationProfile || ''}
                                </Typography>
                                {isCustomConfigurationDetected && (
                                  <Tooltip title={t('rSimInventory.customConfigurationDetected')}>
                                    <ConfigurationIcon
                                      width="16px"
                                      height="16px"
                                      role="img"
                                      data-testid="custom-configuration-icon"
                                    />
                                  </Tooltip>
                                )}
                                {isPendingConfiguration && (
                                  <Tooltip title={t('rSimInventory.configurationChangePending')}>
                                    <PendingIcon
                                      width="18px"
                                      height="18px"
                                      role="img"
                                      data-testid="change-pending-icon"
                                    />
                                  </Tooltip>
                                )}
                              </ContentCopyExtension>
                            </Box>

                            <Box sx={{ whiteSpace: 'nowrap' }} data-testid="secondaryText">
                              <ValueBox
                                variant="tableCellSecondary"
                                text={appliedOtaConfigurationVersion || ''}
                              />
                            </Box>
                          </TableCell>

                          <TagsTableCell
                            initialTags={tags?.items.map((tag) => tag.name) || []}
                            id={euicc}
                            hasMore={tags?.hasMore || false}
                          />
                        </CollapseRow>
                      </React.Fragment>
                    );
                  },
                )}
              </RSimTableBase>
            </Box>
            <Box
              sx={{
                flex: 1,
                border: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                borderBottom: 'none',
              }}
            />
          </Box>
        )}
      /> */}
    </KickRsimProvider>
  );
};
