import IconButton from '@mui/material/IconButton';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import {
  gridDetailPanelExpandedRowIdsSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

export const CollapseAllButton = () => {
  const gridApi = useGridApiContext();
  const expandedRows = useGridSelector(gridApi, gridDetailPanelExpandedRowIdsSelector);

  return expandedRows.length > 0 ? (
    <IconButton
      size="small"
      aria-label="collapse all"
      onClick={() => {
        gridApi.current.setExpandedDetailPanels([]);
      }}
    >
      <UnfoldLessRoundedIcon />
    </IconButton>
  ) : null;
};
