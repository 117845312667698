import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { NoRowsOverlay } from './Components/NoRowsOverlay';
import { CellConfiguration, CellLoader } from './Components/Cells/CellLoader';
import React, { useMemo, useState } from 'react';
import {
  DataGridProProps,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { ActionCell } from './Components/Cells/ActionCell';
import { HeaderCell } from './Components/Header/HeaderCell';
import { Pagination } from './Components/Pagination/Pagination';
import { SkeletonLoader } from './Components/Loaders/SkeletonLoader';

import { styled } from '@mui/material';
import { useAbility } from 'permissions/hooks/useAbility';

export type Configuration = Pick<
  GridColDef,
  | 'field'
  | 'resizable'
  | 'sortable'
  | 'headerName'
  | 'pinnable'
  | 'flex'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
> & { subField?: { field: string; headerName: string } };

type ConfigurableTableProps = {
  disablePagination?: boolean;
  disableColumnReorder?: boolean;
  configuration: Configuration[];
  cellConfiguration: CellConfiguration;
  cellProps?: any;
  //TODO: Imporve typings (generic)
  rows?: Record<string, any>[];
  totalNumberOfItems?: number;
  actions?: any;
  'data-testid'?: string;
  page?: number;
  pageSize?: number;
  onPaginationChange?: (pagination: GridPaginationModel) => void;
  slots?: DataGridProProps['slots'];
  getDetailPanelContent?: DataGridProProps['getDetailPanelContent'];
  getDetailPanelHeight?: DataGridProProps['getDetailPanelHeight'];

  loading?: DataGridProProps['loading'];
  getRowId?: DataGridProProps['getRowId'];
  gridRef?: DataGridProProps['apiRef'];
};

//To remove when use this component in SimInventory
const CustomizeMuiDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-skeletonHeaderRow': {
    backgroundColor: theme.palette.white,
  },
  '& .MuiDataGrid-container--top::after': {
    backgroundColor: theme.palette.white,
  },
  '& .MuiDataGrid-columnHeader': {
    borderBottom: 'none',
    backgroundColor: theme.palette.white,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.white,
    '& > *': {
      backgroundColor: `${theme.palette.white} !important`,
    },
  },
  '& .MuiDataGrid-cell': {
    borderTop: `1px solid ${theme.palette.tableBorder.main}`,
  },
  '& .MuiDataGrid-bottomContainer': {
    border: 'none',
    height: 0,
  },
}));

export const configurableCellRender =
  (cellConfiguration: any, cellProps?: any) => (params: GridRenderCellParams) => {
    return <CellLoader {...params} cellConfiguration={cellConfiguration} cellProps={cellProps} />;
  };

export const SecondaryConfigurableTable = ({
  disablePagination,
  configuration,
  cellConfiguration,
  cellProps,
  rows = [],
  totalNumberOfItems,
  actions,
  page = 0,
  pageSize = 25,
  onPaginationChange,
  slots,
  gridRef,
  ...props
}: ConfigurableTableProps) => {
  const ability = useAbility();
  const dialogsToRender = useMemo(() => {
    const availableActions = actions.filter((action: any) => {
      if (action.type !== 'dialog') {
        return false;
      }

      if (action.permision && ability.cannot(action.permision.action, action.permision.subject)) {
        return false;
      }

      return true;
    });

    return availableActions;
  }, []);

  const [dialogs, setDialogs] = useState(() => {
    return dialogsToRender.reduce((dialogs: any, currentValue: any) => {
      return {
        ...dialogs,
        [currentValue.id]: null,
      };
    }, {});
  });

  const selectDialog = (dialogId: string, row: any) => {
    setDialogs((currentDialogs: any) => ({
      ...currentDialogs,
      [dialogId]: row,
    }));
  };

  const unselectDialog = (dialogId: string) => {
    setDialogs((currentDialogs: any) => ({
      ...currentDialogs,
      [dialogId]: null,
    }));
  };

  const columnsConfiguration = useMemo(() => {
    const columns = configuration.map((column) => {
      const columnDef: GridColDef = {
        ...column,
        renderCell: configurableCellRender(cellConfiguration, cellProps),
        renderHeader: (props) => {
          return (
            <HeaderCell
              primaryText={props.colDef.headerName || ''}
              //@ts-ignore
              secondaryText={props.colDef.subField?.headerName}
            />
          );
        },
      };

      return columnDef;
    });

    columns.push({
      field: 'filler',
      headerName: '',
      width: 0,
      minWidth: 0,
      sortable: false,
      resizable: false,
    });

    if (actions) {
      const mappedAction = actions.map((action: any) => {
        if (action.type === 'dialog') {
          return {
            ...action,
            actionCallback: (data: any) => selectDialog(action.id, data),
          };
        }

        return action;
      });

      const actionsColDef: GridColDef = {
        field: 'actions',
        headerName: '',
        resizable: false,
        width: 60,
        minWidth: 60,
        maxWidth: 60,

        renderCell: (props) => {
          return <ActionCell actions={mappedAction} data={props.row} />;
        },

        sortable: false,
        pinnable: false,
      };
      columns.push(actionsColDef);
    }

    return columns;
  }, [configuration, actions, props.getDetailPanelContent !== undefined]);

  const paginationModel = useMemo(() => {
    return {
      page: page,
      pageSize: pageSize,
    };
  }, [page, pageSize]);

  return (
    <>
      <CustomizeMuiDataGrid
        {...props}
        apiRef={gridRef}
        hideFooter={disablePagination}
        pagination={!disablePagination}
        disableColumnMenu
        columns={columnsConfiguration}
        columnBufferPx={999999}
        rowBufferPx={420}
        rowCount={totalNumberOfItems}
        onPaginationModelChange={onPaginationChange}
        paginationModel={paginationModel}
        columnHeaderHeight={props.loading ? 0 : 42}
        rows={rows}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          pagination: Pagination,
          loadingOverlay: () => <SkeletonLoader numberOfRows={2} />,
          ...slots,
        }}
        paginationMode="server"
        pinnedColumns={{
          right: ['actions'],
        }}
      />
      {dialogsToRender.map((action: any) => {
        return (
          <React.Fragment key={action.id}>
            <action.Component
              open={!!dialogs[action.id]}
              data={dialogs[action.id]}
              onClose={() => {
                unselectDialog(action.id);
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
